<template>
    <div id="headerWrapper" class="d-flex flex-row">
        <div id="logo" class="logo-logged" @click.stop="gotoMain"></div>
        <div
            class="nav-toggle"
            :class="navToggled ? 'toggle' : null"
            @click="toggleNav"
        >
            <div></div>
        </div>
        <SearchBar class="header--search-bar"/>
        <Toolbar class="header--toolbar"/>
    </div>
</template>

<script>
import SearchBar from "./search/SearchBar.vue";
import Toolbar from "./Toolbar.vue";

export default {
    name: "Header",
    components: { SearchBar, Toolbar },
    data() {
      return {
          navToggled: false,
      }
    },
    props: {
        home: String,
    },
    methods: {
        gotoMain() {
            const route = this.$root.getRoute(this.home || 'home')
            this.$root.goToRoute(route)
        },
        toggleNav() {
            this.navToggled = !this.navToggled;
            this.$emit('toggle-sidebar');
        }
    }
}
</script>

<style lang="scss">

#headerWrapper {
    position: relative;
    height: 60px;
    justify-content: space-between;
    z-index: 1000;

    &> .header--search-bar {
        height: 36px;
        top: 12px;
        max-width: 41.66vw;
    }

    &> .header--toolbar {
        width: 25%;
        min-width: 270px;
        max-width: 270px;
        margin-left: 20px;
    }

    &> .nav-toggle {
        margin-right: auto;
        width: 60px;
        height: 60px;
        color: var(--white);
        text-align: center;
        padding: 6px 0;
        cursor: pointer;
        background-color: var(--branding_100);
        z-index: 110;

        &:after,
        &:before,
        div {
            width: 60%;
            background-color: #fff;
            border-radius: 1px;
            content: '';
            display: block;
            height: 3px;
            margin: 9px 0 9px 20%;
            transition: all .3s ease-in-out;
        }

        &.toggle:before {
            transform: translateY(12px) rotate(135deg);
        }

        &.toggle:after {
            transform: translateY(-12px) rotate(-135deg);
        }

        &.toggle div {
            transform: scale(0);
        }

        .i {
            display: inline;
            font-size: 30px;
            line-height: 60px;
        }

        .fa-bars {
            display: none;
        }

        @media screen and (min-width: 1201px) {
            display: none;
        }
    }
}

#logo {
    min-width: 260px;
    max-width: 360px;
    cursor: pointer;

    &.logo-logged {
        max-height: 60px;
        width: auto;
        max-width: 210px;
        margin: 10px 20px 10px 10px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center left;
        background-image: url("../../../../public/assets/cb_logo.svg");
    }
}

@media only screen and (max-width: 1200px) {
    #logo {
        display: none;
    }
    #headerWrapper {
        justify-content: flex-end;

        &> .header--search-bar {
            padding-left: 20px;
            max-width: unset;
        }
        &> .header--toolbar {
            min-width: 100px;
            max-width: 200px;
        }
    }
}
@media only screen and (max-width: 1400px) {
    #logo {
        min-width: 180px;
    }

    #headerWrapper > .header--toolbar {
        max-width: 180px;
    }
}
</style>