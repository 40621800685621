const Collection = require('./collection')

/**
 * @property {Number} type
 * @property {Object} content
 * @property {ViewRule[]} rules
 */
class View {

    static TYPE = {
        INFO: 1,
        EDIT: 2,
        SEARCH: 3,
        LIST_TABLE: 4,
        LIST_GALLERY: 5,
        LIST_SHORT: 6,
        LIST_DETAIL: 7,
        SORTING: 8,
        LIST_DOCUMENT: 9,
        DOWNLOAD_MASK: 10,
        COMPARE: 11,
        LIST_TILE: 12
    }

    constructor(data) {
        //id is a fallback to type, so collections work properly
        this.type = parseInt(data.Type || data.type || data.id || View.TYPE.INFO, 10)
        this.name = data.TypeName || data.name || null
        //this.moduleTabId = data.ModuleId || data.moduleTabId || null
        //this.moduleType = data.ModuleType  || data.moduleType || null
        //this.creatorId = data.CreatorId || data.creatorId || null
        //this.createData = data.CreateDate || data.createDate || null
        //this.changeDate = data.ChangeDate || data.changeDate || null
        this.content = data.Content || data.content || null
        this.rules = (data.rules || data.RuleCollection || []).map(function (rule) {
            return rule instanceof ViewRule ? rule : new ViewRule(rule)
        });
        this.workViewId = parseInt(data.WorkViewId || data.workViewId || 0, 10)
        //???this.attributeIds = Number[]
    }

    get id () { return parseInt(this.workViewId + '0' + this.type, 10) }

    static getListTypes() {
        return [ View.TYPE.LIST_GALLERY , View.TYPE.LIST_TABLE, View.TYPE.LIST_DOCUMENT ]
    }
}
/** make View.TYPE as CONST */
Object.defineProperty(View, 'TYPE', {
    enumerable: false,
    configurable: false,
    writable: false
})
Object.freeze(View.TYPE)

/**
 * @property {Object[]} layout
 * @property {ViewRule[]} rules
 * @property {string} type
 */
class ViewForm {
    /**
     * @param {View} view
     * @param {string} locale
     */
    constructor(view, locale) {
        if (!view) {
            this.type = null
            this.rules = []
            this.layout = []
            return

        } else if (view.type !== View.TYPE.INFO && view.type !== View.TYPE.EDIT && view.type !== View.TYPE.SEARCH)
            throw Error('View.type (' + view.type + ') not allowed for this action')

        let isActive = null
        this.type = view.type
        this.rules = view.rules
        this.layout = (view.content||[]).map(tab => {
            const name = Array.isArray(tab.Name)
                ? (tab.Name.find(n => n.LgCode === locale) || tab.Name[0] || {Value: ''}) : {Value: tab.Name}

            return {
                id: tab.Id,
                name: name.Value,
                content: typeof tab.Content === 'object' ? tab.Content instanceof Array ?
                    tab.Content : Object.values(tab.Content) : [],
                default: (!isActive && tab.IsActive) && (isActive = tab.Id) && true,
                l10n: Array.isArray(tab.Name) ? tab.Name : [name]
            }
        })
        if (!isActive && this.layout.length > 0) this.layout[0].default = true
    }

    /**
     * @returns {array}
     */
    getLayoutAttributeIds() {
        const attributes = []
        for(let i = 0; i < this.layout.length; i++) {
            let attrs = [].concat(...(this.layout[i].content || []).map(content =>
                (content.elements || []).map(it =>
                    !it.Element ? it.Id : null
                )
            ))
            attributes.push(...attrs.filter((v) => !!v && !attributes.includes(v)))
        }
        return attributes
    }

    /**
     * @param {ObjectAttribute[]} attributes
     * @param {string} locale
     * @param {boolean} useRules
     * @param {boolean} attributesOnly
     * @returns {Object[]}
     * @todo: reduce dividers
     */
    filter(attributes, locale, useRules = true, attributesOnly = false) {
        const filtered = []
        for(let i = 0; i < this.layout.length; i++) {
            const tab = Object.assign({}, this.layout[i])
            const content = [].concat(tab.content)
            for (let r = 0; r < content.length; r++) {
                content[r] = Object.assign({}, content[r])
                const elements = [].concat(content[r].elements || [])
                for (let c = 0; c < elements.length; c++) {
                    const type = elements[c].Element || null
                    content[r].elements[c].ix = c //set strict index id to avoid component rerender
                    if (useRules && !this.checkByRules(elements[c], attributes)) {
                        elements[c] = null

                    } else if (type) { //Divider & Button
                        if (attributesOnly) elements[c] = null
                        //todo dividers
                        break
                    } else {
                        const attr = attributes.find(a => '' + a.id === elements[c].Id && this.checkByPerm(a))
                        if (attr) {
                            const vl = attr.multilingual ? locale : '0';
                            if (this.type === View.TYPE.INFO && !attr.value[vl].raw) {
                                elements[c] = null;
                            } // else rules
                        } else elements[c] = null // attribute not even found
                    }
                }
                content[r].ix = r
                content[r].elements = elements.filter(e => !!e)
                if (content[r].elements.length === 0) content[r] = null;
            }
            tab.content = content.filter(r => !!r)
            if (tab.content.length > 0) filtered.push(tab);
        }
        return filtered;
    }

    /**
     * @param {Object} element
     * @param {ObjectAttribute[]} attributes
     * @returns {boolean}
     */
    checkByRules = function(element, attributes) {
        const id = element.Id || 0
        const type = element.Element ? element.Element === 'Button' ?
            ViewRuleResult.TYPE_BUTTON : null : ViewRuleResult.TYPE_ATTRIBUTE

        let shown = true
        if (type && id) for (const rule of this.rules) {
            let checked = rule.check(id, type, attributes)
            if (ViewRule.CHECK_MATCHED === checked) {
                return true
            } else if (ViewRule.CHECK_MISMATCHED === checked) {
                shown = false
            }
        }
        return shown
    }

    /**
     * @param {ObjectAttribute} attribute
     * @returns {Boolean}
     */
    checkByPerm(attribute) {
        return this.constructor.checkByPerm(this.type, attribute)
    }

    /**
     * @param {Number} type
     * @param {ObjectAttribute} attribute
     * @returns {Boolean}
     */
    static checkByPerm(type, attribute) {
        return (type === View.TYPE.EDIT && attribute.writable)
            || (type !== View.TYPE.EDIT && attribute.readable)
    }
}

/**
 * @property {ViewRuleAttribute[]} attributes
 * @property {ViewRuleResult[]} results
 */
class ViewRule {
    static CHECK_MATCHED = 1
    static CHECK_MISMATCHED = -1
    static CHECK_SKIPPED = 0

    constructor(data) {
        this.attributes = (data.attributes || data.Attributes || []).map(function (v) { return new ViewRuleAttribute(v) })
        this.results = (data.results || data.Results || []).map(function (v) { return new ViewRuleResult(v) })
    }

    /**
     * @param {string|numeric} id
     * @param {string} type
     * @param {ObjectAttribute[]} attributes
     * @returns {int}
     */
    check(id, type, attributes) {
        for (const result of this.results) {
            if (result.type !== type || result.id !== id) continue
            let match = true

            for (const rAttr of this.attributes) {
                let attrValue = ''
                const attr = attributes.find(a => '' + a.id === '' + rAttr.id)
                for (const locale in (attr ? attr.value : [])) {
                    attrValue += (attrValue ? ',' : '') + attr.value[locale].raw
                }

                const attrOptions = attr?.isChoosable() ? attrValue.split(',') : [attrValue]
                if (
                    (rAttr.action === ViewRuleAttribute.ACTION_FILL && !attrValue) ||
                    (rAttr.action === ViewRuleAttribute.ACTION_SET
                        && attrOptions.indexOf(rAttr.optionValue) === -1)
                ) {
                    match = false
                    break
                }
            }
            return (
                this.attributes.length === 0 ||
                (!match && result.action === ViewRuleResult.ACTION_SHOW) ||
                (match && result.action !== ViewRuleResult.ACTION_SHOW)//.ACTION_HIDE
            ) ? this.constructor.CHECK_MISMATCHED : this.constructor.CHECK_MATCHED
        }
        return this.constructor.CHECK_SKIPPED
    }
}

/**
 * @property {String} id
 * @property {String|Number} action
 * @property {?String} optionValue
 */
class ViewRuleAttribute {
    static ACTION_SET = 'set';
    static ACTION_FILL = 'fill';

    constructor(data) {
        this.id = data.id || data.Id || null
        this.action = data.action || data.Action || ViewRuleAttribute.ACTION_SET;
        this.optionValue = data.optionValue || data.OptionValue || null;
    }
}

/**
 * @property {String} action
 * @property {String} type
 * @property {String|Number} id
 */
class ViewRuleResult {
    static ACTION_SHOW = 'show';
    static ACTION_HIDE = 'hide';

    static TYPE_ATTRIBUTE = 'A';
    static TYPE_BUTTON = 'B';

    constructor(data) {
        this.action = data.action || data.Action || ViewRuleResult.ACTION_SHOW;
        this.type = data.type || data.Type || ViewRuleResult.TYPE_ATTRIBUTE
        this.id = data.id || data.Id || null
    }
}
/*
ViewRule {
    "Attributes": [
        {
            "Id": 115,
            "Action": "set",
            "OptionValue": "1",
        },
        {
            "Id": 115,
            "Action": "set",
            "OptionValue": "4"
        }
    ],
    "Results": [
        {
            "Action": "show",
            "Type": "A",
            "ObjectId": "114"
        }
    ]
},*/

/**
 *
 */
class ViewCollection extends Collection {
    /**
     * @param {Object} elem
     * @returns {View}
     */
    castObject(elem) {
        return elem instanceof View ? elem : new View(elem)
    }

    /**
     * @param {Number} type
     * @param {Number} workViewId
     * @returns {View}
     */
    findByType(type, workViewId = 0) {
        return this.find(v => v.type === type && workViewId === v.workViewId)
    }

    /**
     * @param {Number} workViewId = 0
     * @returns {Array}
     */
    getSortingList(workViewId = 0) {
        const sorting = this.findByType(View.TYPE.SORTING, workViewId)
        return (sorting && (sorting.content instanceof Array)) ? sorting.content.map(function(item){
            return item.Id
        }) : [ ]
    }

    /**
     * @returns {ViewCollection}
     * @candidate
     */
    findListViews() {
        const types = View.getListTypes()
        const views = new ViewCollection()
        for (let i = 0; i < this.length; i++) {
            if (types.indexOf(this[i].type) > -1) views.push(this[i])
        }
        return views
    }
}

module.exports = {
    View,
    ViewForm,
    ViewRule,
    ViewRuleAttribute,
    ViewRuleResult,
    ViewCollection,
    VIEW_TYPE: View.TYPE
}