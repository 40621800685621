<template>
    <div
        class="input-wrapper"
        :class="
            {
                'input-element-list': inputList, 
                'small-mode': smallMode, 
                'showAdditionalButtons': toggleAdditional,
                disabled: disabled,
                focused: focused,
            }"
    >
        <div
            class="input-element"
            :class="[errors ? 'input-errors' : null, slotNotEmpty ? 'input-with-additional' : null]"
            @click="focus"
        >
            <slot name="label">
                <label v-if="label !== null && label !== undefined" :for="labelFor">
                    <TextOverflow>
                        {{ label }}
                    </TextOverflow>
                    <sup v-if="required">*</sup>
                    <span v-if="helpText" class="label-button help-text" v-tooltip="helpText"><IconLight icon="info-circle" /></span>
                    <span v-if="expand" class="label-button" @click.prevent="$emit('update:expand', !expand)"><IconCustom icon="text-add"/></span>
                    <span v-if="toggleAllEnabled" class="label-button toggle-all" @click.prevent="$emit('toggleAll')" v-tooltip="$root.l10n('choose_all')"><IconLight icon="check-double"/></span>
                    <span v-if="resettable" class="label-button reset" @click.prevent="$emit('reset')" v-tooltip="$root.l10n('reset_to_original_value')"><IconLight icon="undo"/></span>
                    <span v-if="errors"  class="label-button" v-tooltip="errorMessage"><IconSolid icon="exclamation-triangle" class="text-alert alert-danger"/></span>
                    <slot name="status-bar-additional" />
                </label>
            </slot>
            <div class="input-group" v-resize-observer="resizeHandler" ref="inputGroup"><slot name="default" /></div>
        </div>
        <div ref="additional" class="additional" v-if="slotNotEmpty">
            <slot name="additional" />
        </div>
        <button class="additionalDropdownButton" type="button" @click="toggleAdditional = !toggleAdditional" tabindex="-1" v-if="slotNotEmpty">
            <IconSolid icon="ellipsis-h"/>
        </button>
    </div>
</template>

<script>
import {Comment} from 'vue'
import IconLight from '../utility/IconLight'
import TextOverflow from "../utility/TextOverflow"
import IconSolid from "@/components/utility/IconSolid"
import IconCustom from "@/components/utility/IconCustom"
const {isChildOf} = require('../../lib/utility')
import resizeObserver from "@/lib/mixins/resizeObserver"

export default {
    name: "InputWrapper",
    components: {IconCustom, IconSolid, TextOverflow, IconLight},
    props: {
        focused: Boolean,
        disabled: Boolean,
        required: Boolean,
        label: String,
        labelFor: String,
        helpText: String,
        inputList: Boolean,
        resettable: Boolean,
        expand: Boolean,
        toggleAllEnabled: Boolean,
        errors: [Object, Array, String]
    },
    directives: {
        resizeObserver
    },
    data() {
        return {
            smallMode: false,
            toggleAdditional: false
        }
    },
    computed: {
        errorMessage () {
            if (this.errors && (this.errors instanceof Array)) {
                return this.errors.join('; ') || this.$root.l10n('error_occurred')
            } else if (this.errors instanceof Error) {
                return this.errors.message
            } else if (this.errors && (this.errors instanceof Object)) {
                let message = ''
                for (let k of Object.keys(this.errors)) {
                    if (typeof this.errors[k] !== 'boolean') {
                        message += this.errors[k] + '. '
                    } else if (k === 'required' && this.errors[k] !== false) {
                        message += 'required'
                    } else message += `Error(${k}); `
                }
                return message || this.$root.l10n('error_occurred')
            } else return this.errors ? this.errors : ''
        },
        warnRequired() {
            return this.errors && this.errors instanceof Object && this.errors.required || false
        },
        slotNotEmpty() {
            return this.$slots.additional && this.$slots.additional().some(o => o.children?.length > 0 && o.type !== Comment)
        }
    },
    methods: {
        focus(event) {
            //console.log('focus event ' , event)
            if (this.focused) return ;
            if (!isChildOf(event.target, ['i', 'additional', 'additional-inline', 'mx-datepicker-main', 'upload-container', 'input-textarea-headline', 'fr-link-attr'], this.$refs.inputElement?.$el))
                this.$emit('focus', event)
        },
        resizeHandler() {
            if (this.slotNotEmpty) {
                const inputWrapperWidth = this.$el.clientWidth
                const additionalWidth = this.$refs.additional?.clientWidth || 0
                this.smallMode = (inputWrapperWidth - additionalWidth < 201) && (this.$refs.additional?.children.length > 1)
            }
        }
    }
}
</script>