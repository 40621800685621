<template>
    <div id="searchPanelContent" class="tab-container">
            <TabMenu v-if="tabsCount > 1" :tabs="tabs" :tab="tab" @click="tab = $event"/>
            <div class="tab-content scrollable" ref="tabContent" >
                <SearchOptionTab :active="tabsCount === 1 || tab === 'opt'" :modelValue="settings" @update:modelValue="emitSettings"/>
            </div>
    </div>
</template>

<script>
import TabMenu from "@/components/forms/TabMenu"
import SearchOptionTab from "./SearchOptionTab"

export default {
    name: "SearchPanel",
    props: {
        filter: Object,
        terms: Object,
        settings: Object
    },
    components: {
        TabMenu,
        SearchOptionTab
    },
    data() {
        return {
            tab: null
        }
    },
    computed: {
        tabs () {
            return this.$options.name === 'SearchPanel' ? {
                opt: this.$root.l10n('search_options')
            } : {
                gen: this.$root.l10n('general'),
                opt: this.$root.l10n('search_options')
            }
        },
        tabsCount () {
            return Object.keys(this.tabs).length
        }
    },
    methods: {
        /** */
        changed (id, search, term, title) {
            console.log('changed', id, search, term, title) ;
            const value = Object.assign({}, this.filter || {})
            const terms = Object.assign({}, this.terms || {})

            value[id] = search
            terms[id] = {terms: term, title: title}
            for (const k in value) {
                if (!value[k]) {
                    delete terms[k]
                    delete value[k]
                }
            }
            this.emitInput(value, terms)
        },
        /**
         * @param {object} value
         * @param {object} terms
         */
        emitInput(value, terms) {
            if (this.filter.qt) Object.assign(value, {qt: this.filter.qt})
            //this.$emit('update:modelValue', modelValue, terms)
            this.$emit('update:filter', value)
            this.$emit('update:terms', terms)
        },
        /** */
        emitSettings(settings) {
            this.$emit('update:settings', settings)
        }
    }
}
</script>

<style lang="scss">
#searchPanelContent {
    position: relative;
    width: 100%;
    border-radius: 5px;
    overflow: hidden;
    background-color: var(--white);

    .tab-content {
        position: relative;
        max-height: calc(100vh - 100px);

        .tab, .form-section-container {
            min-height: 100%;
            background-color: var(--grey_5);


        }
    }
    .tab-nav + .tab-content {
        background-color: var(--grey_5);
        max-height: calc(100vh - 160px);
        min-height: 400px;
    }

    > .spinner-wrapper {
        min-height: 100px;
    }
}

</style>