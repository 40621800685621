<template>
    <ElementGroup v-if="value || value === 0 || $slots.default" info>
        <div class="input-wrapper text-output" :class="{toggle, disabled}" v-resize-observer="resizeHandler" ref="inputWrapper">
            <label>{{ name }}</label>
            <div v-if="headlineOutput" class="text-output-headline">{{ headlineOutput }}</div>
            <div class="input-group" v-if="(outputValue instanceof Array)">
                <template v-if="options">
                    <span
                        v-for="(output,ix) in outputValue"
                        :class="tags || output.color ? 'text-output-tags' : null"
                        :style="{ backgroundColor: output.color, color: getTextColor(output.color) }"
                        :key="ix"
                    >
                        <template v-if="!tags && ix !== 0">
                            , {{output.title}}
                        </template>
                        <template v-else>
                            {{output.title}}
                        </template>
                    </span>
                </template>
                <div v-else v-for="(output,ix) in outputValue" :key="ix">
                    <span v-if="html" v-html="output"></span>
                    <span v-else>{{output}}</span>
                </div>
            </div>
            <div class="input-group" v-else>
                <span v-if="html" v-html="outputValue"></span>
                <span v-else-if="value || value === 0">{{outputValue}}</span>
                <span v-else><slot/></span>
            </div>
        </div>
        <button v-if="overflow && !toggle" class="text-link link-blue show-all" @click="toggle = true">{{ $root.l10n('show_it_all') }}</button>
    </ElementGroup>
</template>

<script>
import ElementGroup from '../ElementGroup'
import resizeObserver from "@/lib/mixins/resizeObserver"
import {getTextColor} from '@/lib/utility'

export default {
    name: "TextOutput",
    components: { ElementGroup },
    props: {
        name: String,
        options: Array,
        value: [Array, String, Number, Boolean],
        disabled: Boolean,
        html: Boolean,
        tags: Boolean
    },
    directives: {
      resizeObserver
    },
    data() {
        window.$resizeHandler = () => this.resizeHandler()
        return {
            overflow: false,
            toggle: false
        }
    },
    computed: {
        parsedValue() {
            return this.parseValue((this.value || this.value === 0) ? this.value : '')
        },
        headlineOutput() {
            return this.parsedValue[0]
        },
        outputValue () {
            const value = this.parsedValue[1]

            const non_value = this.$root.l10n('no_value');
            if (this.options?.length > 0) {
                const outputValues = []
                const rawValueArr = typeof value !== 'string' ?
                    value instanceof Array ? value : [value] : value.split(',')
                for (let i = 0; i < this.options.length; i++) {
                    if (this.options[i].value != null && rawValueArr.indexOf(this.options[i].value) !== -1)
                    outputValues.push({title: this.options[i].title, color: this.options[i].color})
                }
                //console.log('outputValues ' , outputValues)
                return outputValues.length > 0 ? outputValues : non_value
            } else if (typeof value === 'boolean') {
                return value ? this.$root.l10n('active') : this.$root.l10n('inactive')
            }
            return value || value === 0 ? value : non_value
        }
    },
    methods: {
        getTextColor,
        parseValue(value) {
            const pos = value && typeof value === 'string' ? value.indexOf("\n\n\n") : -1
            return pos > -1 ? [value.substring(0, pos), value.substring(pos + 3)] : ['', value]
        },
        resizeHandler() {
            //hook: fix FF font rendern issue, calculate content height manually
            let lineHeightDiff = 0
            for (let child of this.$refs.inputWrapper?.children || []) {
                lineHeightDiff += Math.max(child.scrollHeight, child.offsetHeight) - child.offsetHeight
            }
            this.overflow = this.$refs.inputWrapper.scrollHeight - lineHeightDiff > this.$refs.inputWrapper.clientHeight
        }
    }
}
</script>