<template>
    <div id="searchPanelLayer" :class="{open: open}">
        <div id="searchPanel" v-bind="$attrs">
            <slot />
        </div>
    </div>
</template>

<script setup>
import { toRef, useAttrs } from 'vue'

defineOptions({
    inheritAttrs: false
})

const props = defineProps({
    open: Boolean
});

const open = toRef(props, 'open')
const $attrs = useAttrs()

</script>

<style lang="scss">
#searchPanelLayer {
    display: none;
    position: fixed;
    content: "";
    top: 60px;
    left: 0;
    width: 100%;
    height: calc(100% - 60px);
    background-color: rgba(0,0,0,0.7);
    z-index: 60;

    &.open {
        display: block;
    }

    #searchPanel {
        position: fixed;
        top: 80px;
        left: 50%;
        width: 90%;
        max-width: 1600px;
        transform: translateX(-50%);
        z-index: 100;

        .form-element .form-group .input-wrapper.choicebox-wrapper .input-element,
        .form-element .info-group .input-wrapper.choicebox-wrapper .input-element {
            padding-right: 120px;
        }
    }
}

@media screen and (max-height: 500px) {
    #searchPanelLayer #searchPanel .tab-nav + .tab-content {
        max-height: 200px;
    }
}

</style>