<template>
    <div class="dropdown-menu-container " v-click-outside="() => toggler = false">
        <slot name="toggler" :toggle="toggle" :toggler="toggler">
            <button @click.prevent="toggle" class="dropdown-menu-btn" :class="{disabled}" tabindex="-1" ref="toggle">
                <IconLight :icon="icon" />
            </button>
        </slot>
        <div v-show="toggler" class="dropdown-menu-list" :class="ddListCSS">
            <div class="triangle"></div>
            <div v-if="!activeSubmenu" class="dropdown-menu-list-content" :class="{scrollable: this.scrollable}">
                <div v-if="headlined" class="dropdown-menu-list-header">
                    <slot name="headline" :toggle="toggle">
                        <h3><span>{{ headline }}</span></h3>
                    </slot>
                </div>
                <ul class="list" v-if="listed">
                    <slot name="list" :toggle="toggle">
                        <li v-for="(title, key) in list" :key="key" :class="{active: key === active}">
                            <button type="button" @click.stop.prevent="() => activate(key)" >{{ title }}</button>
                        </li>
                        <slot name="list-extended" :activate="activate"></slot>
                        <!-- li class="separated-item text-alert alert-danger"></li -->
                    </slot>
                </ul>
                <slot :toggle="toggle"/>
            </div>
            <template v-for="submenu in submenus" :key="submenu.id">
                <div v-if="activeSubmenu === submenu.id">
                    <div class="dropdown-menu-list-content dropdown-menu-sub" >
                        <div class="dropdown-menu-list-header">
                            <h3 @click.stop="$emit('toggle')">
                                <IconLight icon="chevron-left" />
                                {{ submenu.header }}
                                <div class="btn-std btn-small btn-icon-only" @click.stop="toggle"><IconLight icon="times" /></div>
                            </h3>
                        </div>
                        <slot :name="`subContent-${submenu.id}`" :submenu="submenu"></slot>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import {ref} from 'vue'
import IconLight from '../utility/IconLight'
import {directive as ClickOutside} from "click-outside-vue3"

export const ddSize = {
    medium: 'medium',
    large: 'large',
    xlarge: 'xlarge',
}

/**
 * example
 *  `left-bottom` drop left, triangle is bottom
 *  `bottom-left` drop down, triangle is left
 *  `right-bottom` drop right, triangle is bottom
 *  `bottom-right` drop down, triangle is right
 */
export const ddAlign = {
    topLeft: 'top-left',
    topRight: 'top-right',
    bottomLeft: 'bottom-left',
    bottomRight: 'bottom-right',
    edgeRight: 'edge-right',
    leftBottom: 'left-bottom',
    rightBottom: 'bottom-right',
    rightTop: 'right-top',
    get 'top-left' () { return this.topLeft },
    get 'top-right' () { return this.topRight },
    get 'bottom-left' () { return this.bottomLeft },
    get 'bottom-right' () { return this.bottomRight },
    get 'edge-right' () { return this.edgeRight },
    get 'left-bottom' () { return this.leftBottom },
    get 'right-bottom' () { return this.rightBottom },
    get 'right-top' () { return this.rightTop },

}

export default {
    name: "Dropdown",
    components: {
        IconLight
    },
    directives: {
        ClickOutside
    },
    props: {
        icon: {type: String, default: 'cog'},
        headline: String,
        list: Object,//Array & AssocArray
        active: [String,Number],
        disabled: Boolean,
        scrollable: Boolean,

        size: {
            type: String,
            validate(css) {
                return (css in ddAlign)
            }
        },
        align: {
            type: String,
            default: ddAlign.topRight,
            validate(css) {
                return (css in ddAlign)
            }
        },
        submenus: Array,
        activeSubmenu: String
    },
    setup() {
        return {
            toggler: ref(false),
        }
    },
    computed: {
        headlined() {
            return !!(this.headline || this.$slots.headline)
        },
        listed() {
            return !!(this.list || this.$slots.list)
        },
        ddListCSS() {
            return [
                this.size in ddSize ? 'dropdown-menu-' + ddSize[this.size] : '',
                this.align in ddAlign ? 'dropdown-menu-' + ddAlign[this.align] : '',
            ].join(' ')
        }
    },
    methods: {
        toggle() {
            const toggler = !this.disabled && !this.toggler
            if (toggler !== this.toggler) this.$emit('toggle', toggler)
            return this.toggler = toggler
        },
        toggleOpen() {
            if (!this.toggler) this.toggle()
        },
        toggleClose() {
            if (this.toggler) this.toggle()
        },
        activate(key) {
            this.$emit('update:active', key)
            this.toggle()
        }
    }
}
</script>