import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core'

/********************************************/
/*************  SOLID ICONS  ****************/
/********************************************/
import * as faSolid from '@fortawesome/pro-solid-svg-icons'

library.add(
    faSolid.faArchive,
    faSolid.faBan,
    faSolid.faBars,
    faSolid.faBell,
    faSolid.faBookmark,
    faSolid.faCaretDown,
    faSolid.faCaretUp,
    faSolid.faCheck,
    faSolid.faCheckCircle,
    faSolid.faChevronLeft,
    faSolid.faChevronRight,
    faSolid.faCircleInfo,
    faSolid.faCloud,
    faSolid.faComments,
    faSolid.faCog,
    faSolid.faCopy,
    faSolid.faDesktop,
    faSolid.faDiagramProject,
    faSolid.faDownload,
    faSolid.faSortAmountDown,
    faSolid.faEdit,
    faSolid.faEllipsisH,
    faSolid.faExclamation,
    faSolid.faExclamationTriangle,
    faSolid.faEnvelope,
    faSolid.faFileArrowUp,
    faSolid.faFileCircleCheck,
    faSolid.faFilter,
    faSolid.faFloppyDisk,
    faSolid.faGlobe,
    faSolid.faGrid2,
    faSolid.faHeart,
    faSolid.faHouse,
    faSolid.faInboxOut,
    faSolid.faInfo,
    faSolid.faLocationPin,
    faSolid.faLock,
    faSolid.faLevelUp,
    faSolid.faMapMarker,
    faSolid.faMinus,
    faSolid.faMinusOctagon,
    faSolid.faMobile,
    faSolid.faMusic,
    faSolid.faObjectsColumn,
    faSolid.faPalette,
    faSolid.faPaperPlane,
    faSolid.faPlusCircle,
    faSolid.faPrint,
    faSolid.faQuestionCircle,
    faSolid.faRulerCombined,
    faSolid.faSave,
    faSolid.faShare,
    faSolid.faShareSquare,
    faSolid.faSitemap,
    faSolid.faSliders,
    faSolid.faSync,
    faSolid.faTablet,
    faSolid.faThumbsUp,
    faSolid.faThLarge,
    faSolid.faTimes,
    faSolid.faTrash,
    faSolid.faUndo,
    faSolid.faUnlock,
    faSolid.faUpload,
    faSolid.faUser,
    faSolid.faUserGroup,
    faSolid.faVideo
)

/********************************************/
/*************  REGULAR ICONS  **************/
/********************************************/
import * as faRegular from '@fortawesome/pro-regular-svg-icons'

library.add(
    faRegular.faBan,
    faRegular.faCalendarAlt,
    faRegular.faCheck,
    faRegular.faClipboard,
    faRegular.faClockRotateLeft,
    faRegular.faEllipsisV,
    faRegular.faGear,
    faRegular.faGripDotsVertical,
    faRegular.faImage,
    faRegular.faLayerGroup,
    faRegular.faInfoSquare,
    faRegular.faPenSquare,
    faRegular.faPlus,
    faRegular.faSadCry,
    faRegular.faSearch,
    faRegular.faShieldCheck,
    faRegular.faSquarePlus,
    faRegular.faUsers,
)

/********************************************/
/*************  LIGHT ICONS  ****************/
/********************************************/
import * as faLight from '@fortawesome/pro-light-svg-icons'

library.add(
    faLight.faBan,
    faLight.faAlienMonster,
    faLight.faAngleRight,
    faLight.faAnglesDown,
    faLight.faArchive,
    faLight.faArrowDown,
    faLight.faArrowLeft,
    faLight.faArrowRight,
    faLight.faArrowRightFromBracket,
    faLight.faArrowUp,
    faLight.faArrowUpRightAndArrowDownLeftFromCenter,
    faLight.faArrowUpRightFromSquare,
    faLight.faArrowsV,
    faLight.faAsterisk,
    faLight.faAt,
    faLight.faBars,
    faLight.faBlock,
    faLight.faBlockBrick,
    faLight.faBlockQuestion,
    faLight.faBook,
    faLight.faBoxFull,
    faLight.faCalendarAlt,
    faLight.faCaretDown,
    faLight.faChartLine,
    faLight.faCheck,
    faLight.faCheckCircle,
    faLight.faCheckDouble,
    faLight.faChevronDoubleUp,
    faLight.faChevronDown,
    faLight.faChevronDoubleLeft,
    faLight.faChevronDoubleRight,
    faLight.faChevronLeft,
    faLight.faChevronRight,
    faLight.faChevronUp,
    faLight.faChevronsDown,
    faLight.faChevronsUp,
    faLight.faClipboard,
    faLight.faCloud,
    faLight.faCloudUpload,
    faLight.faCloudUploadAlt,
    faLight.faClone,
    faLight.faCodeBranch,
    faLight.faCog,
    faLight.faCogs,
    faLight.faCopy,
    faLight.faCube,
    faLight.faCubes,
    faLight.faDesktop,
    faLight.faDiagramProject,
    faLight.faDiagramVenn,
    faLight.faDownload,
    faLight.faEdit,
    faLight.faEllipsisH,
    faLight.faEllipsisHAlt,
    faLight.faEnvelope,
    faLight.faEnvelopeOpen,
    faLight.faExclamation,
    faLight.faExternalLink,
    faLight.faEye,
    faLight.faEyeSlash,
    faLight.faFile,
    faLight.faFileAlt,
    faLight.faFileArrowUp,
    faLight.faFileContract,
    faLight.faFileExcel,
    faLight.faFileExport,
    faLight.faFileImport,
    faLight.faFilePdf,
    faLight.faFileZipper,
    faLight.faFilter,
    faLight.faFilterSlash,
    faLight.faFingerprint,
    faLight.faFolder,
    faLight.faFolderOpen,
    faLight.faFrog,
    faLight.faFrame,
    faLight.faGlobe,
    faLight.faGrid2,
    faLight.faHandReceiving,
    faLight.faHeart,
    faLight.faHome,
    faLight.faHourglassEnd,
    faLight.faImage,
    faLight.faImages,
    faLight.faInbox,
    faLight.faInboxOut,
    faLight.faInfo,
    faLight.faInfoCircle,
    faLight.faKey,
    faLight.faLayerGroup,
    faLight.faLifeRing,
    faLight.faLink,
    faLight.faList,
    faLight.faListCheck,
    faLight.faLocationPin,
    faLight.faLock,
    faLight.faLongArrowDown,
    faLight.faLongArrowUp,
    faLight.faMinus,
    faLight.faMinusOctagon,
    faLight.faMobile,
    faLight.faMusic,
    faLight.faObjectsColumn,
    faLight.faPalette,
    faLight.faPaperclip,
    faLight.faPaperPlane,
    faLight.faPaperPlaneTop,
    faLight.faPause,
    faLight.faPenNib,
    faLight.faPeopleRoof,
    faLight.faPhone,
    faLight.faPlay,
    faLight.faPlus,
    faLight.faPuzzlePiece,
    faLight.faRedo,
    faLight.faRetweet,
    faLight.faSave,
    faLight.faScaleBalanced,
    faLight.faScissors,
    faLight.faSearch,
    faLight.faFileSearch,
    faLight.faSection,
    faLight.faServer,
    faLight.faShareAlt,
    faLight.faShareSquare,
    faLight.faShieldCheck,
    faLight.faShield,
    faLight.faSitemap,
    faLight.faSlidersUp,
    faLight.faSortAlphaDown,
    faLight.faSortAlphaUp,
    faLight.faSortAmountDown,
    faLight.faSortNumericDown,
    faLight.faSortNumericUp,
    faLight.faStar,
    faLight.faStreetView,
    faLight.faSync,
    faLight.faTabletAndroid,
    faLight.faThLarge,
    faLight.faTimer,
    faLight.faTimes,
    faLight.faTrash,
    faLight.faTurtle,
    faLight.faUndo,
    faLight.faUnicorn,
    faLight.faUnlock,
    faLight.faUpload,
    faLight.faUser,
    faLight.faUserClock,
    faLight.faUserCog,
    faLight.faUsers,
    faLight.faUsersCog,
    faLight.faUserGroup,
    faLight.faUserLock,
    faLight.faUserShield,
    faLight.faUserUnlock,
    faLight.faVideo,
    faLight.faWindowFlip,
    faLight.faWindowMaximize,
    faLight.faWrench,
    faLight.faXmark
)

/********************************************/
/*************  THIN ICONS  **************/
/********************************************/
import * as faThin from '@fortawesome/pro-thin-svg-icons'

library.add(
    faThin.faAlignLeft,
    faThin.faArrowsLeftRight,
    faThin.faArrowsUpDown,
    faThin.faArrowUpRightAndArrowDownLeftFromCenter,
    faThin.faArrowsRotate,
    faThin.faArrowsUpDownLeftRight,
    faThin.faBook,
    faThin.faBookmark,
    faThin.faCodeBranch,
    faThin.faChevronRight,
    faThin.faCircleInfo,
    faThin.faCube,
    faThin.faCubes,
    faThin.faEllipsisV,
    faThin.faEnvelope,
    faThin.faExternalLink,
    faThin.faFileCircleCheck,
    faThin.faFileLines,
    faThin.faFolderOpen,
    faThin.faHouse,
    faThin.faGear,
    faThin.faGlobe,
    faThin.faGrid2,
    faThin.faImage,
    faThin.faImageLandscape,
    faThin.faImagePortrait,
    faThin.faLifeRing,
    faThin.faLink,
    faThin.faMemo,
    faThin.faPanorama,
    faThin.faPaperclip,
    faThin.faPenToSquare,
    faThin.faClock,
    faThin.faCog,
    faThin.faSearch,
    faThin.faShare,
    faThin.faShareNodes,
    faThin.faShieldCheck,
    faThin.faTimes,
    faThin.faThumbsUp,
    faThin.faTrash,
    faThin.faUser,
    faThin.faUsers,
    faThin.faUpRightFromSquare,
    faThin.faUserGroup,
    faThin.faWrench,
    faThin.faXmarksLines
)

/********************************************/
/*************  DUOTONE ICONS  **************/
/********************************************/
import * as faDuo from '@fortawesome/pro-duotone-svg-icons'

library.add(
    faDuo.faArchive,
    faDuo.faHourglassEnd,
    faDuo.faLock,
    faDuo.faShareAlt,
    faDuo.faSpinnerThird,
    faDuo.faTrash,
    faDuo.faUnicorn
)

export default FontAwesomeIcon;